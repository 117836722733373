import React, { useState, useMemo, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  TablePagination,
  TableSortLabel,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";

const LeadTimesTable = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterText, setFilterText] = useState("");
  const [orderBy, setOrderBy] = useState("route");
  const [order, setOrder] = useState("asc");

  const formatValue = (value) => {
    if (value === null || value === undefined) return "N/A";
    return typeof value === "number" ? value.toFixed(2) : value.toString();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filteredAndSortedData = useMemo(() => {
    if (!data || Object.keys(data).length === 0) return [];
    return Object.entries(data)
      .filter(([route]) =>
        route.toLowerCase().includes(filterText.toLowerCase())
      )
      .sort((a, b) => {
        const isAsc = order === "asc";
        if (orderBy === "route") {
          return isAsc ? a[0].localeCompare(b[0]) : b[0].localeCompare(a[0]);
        }
        const aValue = a[1][orderBy]?.avg ?? 0;
        const bValue = b[1][orderBy]?.avg ?? 0;
        return isAsc ? aValue - bValue : bValue - aValue;
      });
  }, [data, filterText, order, orderBy]);

  const paginatedData = filteredAndSortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  if (!data || Object.keys(data).length === 0) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            No lead times data available
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const renderDesktopTable = () => (
    <TableContainer component={Paper} elevation={3}>
      <TextField
        fullWidth
        variant="outlined"
        label="Filter routes"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        sx={{ m: 2, width: "calc(100% - 32px)" }}
      />
      <Table stickyHeader aria-label="lead times table">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>
              <TableSortLabel
                active={orderBy === "route"}
                direction={orderBy === "route" ? order : "asc"}
                onClick={() => handleRequestSort("route")}
              >
                ROUTE
              </TableSortLabel>
            </TableCell>
            {["port_to_port", "customs", "door_to_port", "port_to_door"].map(
              (column) => (
                <TableCell key={column} colSpan={3} align="center">
                  <TableSortLabel
                    active={orderBy === column}
                    direction={orderBy === column ? order : "asc"}
                    onClick={() => handleRequestSort(column)}
                  >
                    {column.replace("_", " ").toUpperCase()}
                  </TableSortLabel>
                </TableCell>
              )
            )}
            <TableCell rowSpan={2} align="center">
              COUNT
            </TableCell>
          </TableRow>
          <TableRow>
            {["port_to_port", "customs", "door_to_port", "port_to_door"].map(
              (column) => (
                <React.Fragment key={column}>
                  <TableCell align="center">Min</TableCell>
                  <TableCell align="center">Avg</TableCell>
                  <TableCell align="center">Max</TableCell>
                </React.Fragment>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map(([route, details]) => (
            <TableRow key={route} hover>
              <TableCell>{route}</TableCell>
              {["port_to_port", "customs", "door_to_port", "port_to_door"].map(
                (column) => (
                  <React.Fragment key={column}>
                    <TableCell align="center">
                      {formatValue(details[column]?.min)}
                    </TableCell>
                    <TableCell align="center">
                      {formatValue(details[column]?.avg)}
                    </TableCell>
                    <TableCell align="center">
                      {formatValue(details[column]?.max)}
                    </TableCell>
                  </React.Fragment>
                )
              )}
              <TableCell align="center">{formatValue(details.count)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredAndSortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );

  const renderMobileCards = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          label="Filter routes"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          sx={{ mb: 2 }}
        />
      </Grid>
      {paginatedData.map(([route, details]) => (
        <Grid item xs={12} key={route}>
          <Card elevation={2}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {route}
              </Typography>
              {["port_to_port", "customs", "door_to_port", "port_to_door"].map(
                (column) => (
                  <Tooltip
                    key={column}
                    title={`Min: ${formatValue(
                      details[column]?.min
                    )}, Max: ${formatValue(details[column]?.max)}`}
                    arrow
                  >
                    <Typography variant="body2" align="center">
                      {column.replace("_", " ")} (Avg):{" "}
                      {formatValue(details[column]?.avg)}
                    </Typography>
                  </Tooltip>
                )
              )}
              <Typography variant="body2" align="center">
                Count: {formatValue(details.count)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredAndSortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Typography variant="h4" gutterBottom component="div" sx={{ p: 2 }}>
        Lead Times by Route
      </Typography>
      {isMobile ? renderMobileCards() : renderDesktopTable()}
    </div>
  );
};

export default LeadTimesTable;
