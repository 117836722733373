import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Paper,
  Grid,
  Divider,
  MenuItem,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { styled } from "@nextui-org/react";
import Button from "../../../components/Button/Button";
import DropZone from "../../../components/DropZone/DropZone";
import {
  getShippingBillOrgType,
  getShippingBillOrganization,
} from "../../../services/shippingbill";
import { useSelector } from "react-redux";
import NewOrganizationModal from "./NewShippingBillOrg";
import {getQuoteData, sendInstructions } from "../../../services/quotations.services";
import {getServiceSplitRadication} from "../../../services/quotation.services";
import { getBusinessTerms } from "../../../services/businessTerms.services";
import { getAgents, getOperationStatuses } from '../../../services/operations.services';
import { getLoadTypes } from '../../../services/preliquidation.services'
import toast from '../../../components/Toast/ToastTypes';

const validationSchema = Yup.object({
  shipper: Yup.string().required("Requerido"),
  consignee: Yup.string().required("Requerido"),
  notify: Yup.string(),
  description: Yup.string(),
  origin: Yup.object().shape({
    country: Yup.string().required("El país de origen es obligatorio"),
    address: Yup.string().required("La dirección de origen es obligatoria"),
    additionalInfo: Yup.string(),
  }),
  destination: Yup.object().shape({
    country: Yup.string().required("El país de destino es obligatorio"),
    address: Yup.string().required("La dirección de destino es obligatoria"),
    additionalInfo: Yup.string(),
  }),
  additionalInfo: Yup.array().of(Yup.string()),
  incoterm: Yup.string().required("Requerido"),
  shippingLine: Yup.string().required("Requerido"),
  cargoType: Yup.string().required("Requerido"),
  containerType: Yup.string(),
  quantity: Yup.string().required("Requerido"),
  netRate: Yup.string().required("Requerido"),
  saleRate: Yup.string().required("Requerido"),
  assignedAgent: Yup.string().required("Requerido"),
  grossWeight: Yup.string(),
  netWeight: Yup.string(),
  isInsured: Yup.boolean(),
  temperature: Yup.boolean(),
  dangerous: Yup.boolean(),
  nonStackable: Yup.boolean(),
  attachments: Yup.array().of(Yup.mixed()),
  additionalComments: Yup.string(),
});

const SectionTitle = ({ title }) => (
  <Box sx={{ mt: 4, mb: 2 }}>
    <Typography
      variant="subtitle1"
      sx={{ color: "#292e68", fontWeight: "bold" }}
    >
      {title}
    </Typography>
    <Divider sx={{ mt: 1 }} />
  </Box>
);

const StyledPaper = styled(Paper, {
  padding: "2rem",
  maxWidth: "1400px",
  margin: "2rem auto",
  borderRadius: "12px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const StyledTextField = styled(TextField, {
  margin: "1rem 0",
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#3D509A",
    },
    "&:hover fieldset": {
      borderColor: "#3D509A",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3D509A",
    },
  },
});

const TransportForm = ({currentQuote}) => {
  const navigate = useNavigate();
  console.log(currentQuote, "currentQuote");
  const [files, setFiles] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organizationTypes, setOrganizationTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [currentOrgType, setCurrentOrgType] = useState(null);
  const [quoteData, setQuoteData] = useState([]);
  const [serviceSplitData, setserviceSplitData] = useState();
  const [businessTermsData, setBusinessTermsData] = useState();
  const [agentsData, setAgentsData] = useState();
  const [loadTypesData, setLoadTypesData] = useState();
  const [operationStatusesData, setOperationStatusesData] = useState();

  const inputContainerType = [
    { label: "20", value: 1 },
    { label: "40", value: 2 },
    { label: "40HC", value: 3}
  ]
  const currentUser = useSelector((state) => state.user);
  const token = currentUser.token;
  // console.info("ELTOKEN " + token);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          quoteResponse,
          serviceSplitResponse,
          businessTermsResponse,
          agentsResponse,
          operationStatusesResponse,
          loadTypesResponse
        ] = await Promise.all([
          getQuoteData(token, currentQuote.id),
          getServiceSplitRadication(`?page=1&page_size=1&search=${currentQuote.radication}`, token),
          getBusinessTerms(token),
          getAgents(token), 
          getOperationStatuses(token),
          getLoadTypes(token)
        ]);
        if (
          quoteResponse.ok && 
          serviceSplitResponse.ok && 
          businessTermsResponse.ok && 
          agentsResponse.ok && 
          operationStatusesResponse.ok && 
          loadTypesResponse.ok
        ) {
          const quoteData = await quoteResponse.json();
          const serviceSplitData = await serviceSplitResponse.json();
          const businessTermsData = await businessTermsResponse.json();
          const agentsData = await agentsResponse.json();
          const operationStatusesData = await operationStatusesResponse.json();
          const loadTypesData = await loadTypesResponse.json();
          
          console.log(quoteData, "quoteData");
          console.log(serviceSplitData, "serviceSplitData");
          console.log(businessTermsData, "businessTermsData");
          console.log(agentsData, "agentsData");
          console.log(operationStatusesData, "operationStatusesData");
          console.log(loadTypesData, "loadTypesData");
          
          setQuoteData(quoteData);
          setserviceSplitData(serviceSplitData);
          setBusinessTermsData(businessTermsData); 
          setAgentsData(agentsData); 
          setOperationStatusesData(operationStatusesData);
          setLoadTypesData(loadTypesData);
          
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [currentQuote, token]);

  const addFiles = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [orgResponse, orgTypeResponse] = await Promise.all([
          getShippingBillOrganization(token),
          getShippingBillOrgType(token),
        ]);
        // console.info("Organization Response:", orgResponse);
        // console.info("Organization Type Response:", orgTypeResponse);

        if (orgResponse.ok && orgTypeResponse.ok) {
          const orgData = await orgResponse.json();
          const orgTypeData = await orgTypeResponse.json();
          setOrganizations(orgData);
          setOrganizationTypes(orgTypeData);
        } else {
          console.error("Failed to fetch organization data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  const filterOrganizations = (typeId) => {
    return organizations.filter(
      (org) =>
        org.type_organization.includes(typeId) &&
        org.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpenModal = (orgType) => {
    setCurrentOrgType(orgType);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCreateOrganization = (values) => {
    // Implement the logic to create a new organization
    console.log("Creating new organization:", values);
    // After creating, you should update the organizations list
    // and close the modal
    handleCloseModal();
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const valuesCopy = { ...values }; 
      const radication = currentQuote.radication;

      valuesCopy.shipper = filterOrganizations(2).find(org => org.id === values.shipper)?.name || "";
      valuesCopy.consignee = filterOrganizations(1).find(org => org.id === values.consignee)?.name || "";
      valuesCopy.notify = filterOrganizations(3).find(org => org.id === values.notify)?.name || "";
      valuesCopy.incoterm = businessTermsData.find(term => term.id === values.incoterm)?.name || "";
      valuesCopy.assignedAgent = agentsData.find(agent => agent.id === values.assignedAgent)?.name || "";
      valuesCopy.cargoType = loadTypesData.find(load => load.id === values.cargoType)?.name || "";
      
      if (serviceSplitData?.results[0]) {
        const findContainerType = inputContainerType.find(
          (valueContainer) => valueContainer.label === serviceSplitData.results[0].loads[0].containers
        );
        valuesCopy.containerType = findContainerType?.name || "N/A";
      }
      
      const updatedValues = {
        shipping_instructions: JSON.stringify(valuesCopy),
        radication: radication
      };

      console.log('Enviando datos:', updatedValues);
      const response = await sendInstructions(updatedValues, token);
      console.log('Respuesta completa del servidor:', response);

      if (response && typeof response === 'object' && response.id) {
        console.log('Operación exitosa');
        toast('success', 'Se ha creado una nueva orden de instrucciones');
        resetForm(); 
      } else {
        console.log('La respuesta del servidor no fue considerada exitosa');
        const errorMessage = response?.message || 'Error desconocido';
        toast('error', `Error: ${errorMessage}`);
      }
    } catch (error) {
      console.error("Error sending shipping instructions:", error);
      toast('error', 'Error al crear la orden de instrucciones, verifique si ya existe ');
    } finally {
      setSubmitting(false);
    }
  };

  const calculateTotals = (preliquidations) => {
    let totalNetRate = 0;
    let totalSaleRate = 0;
  
    preliquidations.forEach(preliquidation => {
      preliquidation.items.forEach(item => {
        if (item.rate && typeof item.rate.net_rate === 'number') {
          totalNetRate += item.rate.net_rate;
        }
        if (typeof item.total_in_usd === 'number') {
          totalSaleRate += item.total_in_usd;
        }
      });
    });
  
    return {
      netRate: totalNetRate.toFixed(2),
      saleRate: totalSaleRate.toFixed(2)
    };
  };
      
  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;
  
  return (
    <StyledPaper
      elevation={3}
      sx={{ p: 4, maxWidth: 1400, my: 4, borderRadius: 2 }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 4,
        }}
      >
        <Typography variant="h4" sx={{ color: "#292e68", fontWeight: "bold" }}>
          Instrucciones de Envío
        </Typography>
      </Box>
      <Formik
        initialValues={{
          shipper: "",
          consignee: "",
          notify: "",
          description: "",
          origin: {
            country: "",
            address: "",
            additionalInfo: "",
          },
          destination: {
            country: "",
            address: "",
            additionalInfo: "",
          },
          incoterm: "",
          shippingLine: "",
          cargoType: "",
          containerType: "",
          quantity: "",
          netRate: "",
          saleRate: "",
          assignedAgent: "",
          grossWeight: "",
          netWeight: "",
          isInsured: false,
          temperature: false,
          dangerous: false,
          nonStackable: false,
          attachments: [],
          additionalComments: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          setFieldValue,
          isSubmitting 
        }) => {

          useEffect(() => {
            if (serviceSplitData?.results[0]) {
              const findContainerType = inputContainerType.find(
                (valueContainer) =>
                  valueContainer.label ==
                  serviceSplitData.results[0].loads[0].containers
              );
              const serviceOptions = serviceSplitData.results[0].service_options;
              setFieldValue("containerType", findContainerType?.value || "");
              setFieldValue("origin.address", serviceSplitData.results[0].shipping.pickup_address);
              setFieldValue("destination.address", serviceSplitData.results[0].shipping.delivery_address);
              setFieldValue("description",serviceSplitData?.results?.[0]?.service_options?.imo ?? "");
              setFieldValue("origin.additionalInfo",serviceSplitData.results[0].shipping.pickup_comments);
              setFieldValue("destination.additionalInfo",serviceSplitData.results[0].shipping.delivery_comments);
              setFieldValue("origin.country",`${serviceSplitData.results[0].shipping?.origin_boarding_terminal.city.name}, ${serviceSplitData.results[0].shipping?.origin_boarding_terminal.city.country.name}`);
              setFieldValue("destination.country",`${serviceSplitData.results[0].shipping?.destination_boarding_terminal.city.name}, ${serviceSplitData.results[0].shipping?.destination_boarding_terminal.city.country.name}`);
              setFieldValue("shippingLine",serviceSplitData?.results?.[0].preliquidations?.[0]?.service_details?.airline ?? "");
              setFieldValue("isInsured", !!serviceOptions.shipping_insurance);
              setFieldValue("temperature", !!serviceOptions.temperature_control);
              setFieldValue("dangerous", !!serviceOptions.dangerous);
              setFieldValue("nonStackable", !!serviceOptions.remountable);
              const preliquidations = serviceSplitData?.results?.[0]?.preliquidations;
              if (preliquidations) {
                const { netRate, saleRate } = calculateTotals(preliquidations);
                setFieldValue("netRate", netRate);
                setFieldValue("saleRate", saleRate);
              }
              const loadsCount = serviceSplitData.results[0].loads.length;
              const containerInfo =
                serviceSplitData.results[0].loads[0].containers || "";
              setFieldValue("quantity", `${loadsCount} x ${containerInfo}`);
              setFieldValue("additionalComments", serviceSplitData.results[0].service_options.additional_specifications);
              if (serviceSplitData.results.length > 0) {
                const grossWeight = serviceSplitData.results[0].loads.reduce((total, load) => {
                    return total + load.total_weight;
                  },
                  0
                );
                const netWeight = grossWeight;
                setFieldValue("grossWeight", grossWeight.toFixed(2) + " kg");
                setFieldValue("netWeight", netWeight.toFixed(2) + " kg");
              }
            }

            if (Array.isArray(agentsData) && agentsData.length > 0) {
              const agentId = quoteData?.agent;
              const agent = agentsData.find((agent) => agent.id === agentId);
              setFieldValue("assignedAgent", agent ? agent.id : "");
            }

            if (
              Array.isArray(businessTermsData) &&
              businessTermsData.length > 0
            ) {
              const incotermId = quoteData?.incoterm;
              const incoterm = businessTermsData.find(
                (term) => term.id === incotermId
              );
              setFieldValue("incoterm", incoterm ? incoterm.id : "");
            }

            if (Array.isArray(loadTypesData) && loadTypesData.length > 0) {
              const load_typeId =
                serviceSplitData?.results?.[0]?.preliquidations?.[0]
                  ?.service_details?.load_type;
              const cargoType = loadTypesData.find(
                (load) => load.name === load_typeId
              );
              setFieldValue("cargoType", cargoType ? cargoType.id : "");
            } else {
              setFieldValue("cargoType", "");
            }
          }, [serviceSplitData, setFieldValue]);

          return (
            <Form>
              <Grid container spacing={3}>
                {[
                  { label: "Shipper", name: "shipper", typeId: 2 },
                  { label: "Consignee", name: "consignee", typeId: 1 },
                  { label: "Notify", name: "notify", typeId: 3 },
                ].map((field) => (
                  <Grid item xs={12} md={6} key={field.name}>
                    <StyledTextField
                      select
                      fullWidth
                      label={field.label}
                      name={field.name}
                      value={values[field.name]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched[field.name] && Boolean(errors[field.name])}
                      helperText={touched[field.name] && errors[field.name]}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem>
                        <TextField
                          fullWidth
                          placeholder={`Buscar ${field.label}`}
                          onChange={handleSearch}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </MenuItem>
                      {filterOrganizations(field.typeId).map((org) => (
                        <MenuItem key={org.id} value={org.id}>
                          {org.name}
                        </MenuItem>
                      ))}
                      {filterOrganizations(field.typeId).length === 0 && (
                        <MenuItem onClick={() => handleOpenModal(field.typeId)}>
                          Crear nueva organización
                        </MenuItem>
                      )}
                    </StyledTextField>
                  </Grid>
                ))}
              </Grid>
              <SectionTitle title="Detalles de la Mercancía" />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    label="Descripción de la Mercancía (Revisar si es IMO)"
                    name="description"
                    value={values.description || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    select
                    label="Tipo de Carga"
                    name="cargoType"
                    id="cargoType"
                    value={values.cargoType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.cargoType && Boolean(errors.cargoType)}
                    helperText={touched.cargoType && errors.cargoType}
                  >
                    {loadTypesData?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    select
                    label="Contenedor"
                    name="containerType"
                    value={values.containerType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.containerType && Boolean(errors.containerType)
                    }
                    helperText={touched.containerType && errors.containerType}
                  >
                    {inputContainerType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                </Grid>
              </Grid>
              <SectionTitle title="Origen y Destino" />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    fullWidth
                    label="País de Origen"
                    name="origin.country"
                    value={values.origin.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.origin?.country && Boolean(errors.origin?.country)
                    }
                    helperText={
                      touched.origin?.country && errors.origin?.country
                    }
                  />
                  <StyledTextField
                    fullWidth
                    label="Dirección de Origen"
                    name="origin.address"
                    value={values.origin.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.origin?.address && Boolean(errors.origin?.address)
                    }
                    helperText={
                      touched.origin?.address && errors.origin?.address
                    }
                    margin="normal"
                  />
                  <StyledTextField
                    fullWidth
                    label="Información Adicional de Origen"
                    name="origin.additionalInfo"
                    value={values.origin.additionalInfo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.origin?.additionalInfo &&
                      Boolean(errors.origin?.additionalInfo)
                    }
                    helperText={
                      touched.origin?.additionalInfo &&
                      errors.origin?.additionalInfo
                    }
                    margin="normal"
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    label="País de Destino"
                    name="destination.country"
                    value={values.destination.country}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.destination?.country &&
                      Boolean(errors.destination?.country)
                    }
                    helperText={
                      touched.destination?.country &&
                      errors.destination?.country
                    }
                  />
                  <StyledTextField
                    fullWidth
                    label="Dirección de Destino"
                    name="destination.address"
                    value={values.destination.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.destination?.address &&
                      Boolean(errors.destination?.address)
                    }
                    helperText={
                      touched.destination?.address &&
                      errors.destination?.address
                    }
                    margin="normal"
                  />
                  <StyledTextField
                    fullWidth
                    label="Información Adicional de Destino"
                    name="destination.additionalInfo"
                    value={values.destination.additionalInfo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.destination?.additionalInfo &&
                      Boolean(errors.destination?.additionalInfo)
                    }
                    helperText={
                      touched.destination?.additionalInfo &&
                      errors.destination?.additionalInfo
                    }
                    margin="normal"
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>

              <SectionTitle title="Información Adicional" />
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    select
                    label="Incoterm"
                    name="incoterm"
                    value={values.incoterm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.incoterm && Boolean(errors.incoterm)}
                    helperText={touched.incoterm && errors.incoterm}
                  >
                    {businessTermsData?.map((term) => (
                      <MenuItem key={term.id} value={term.id}>
                        {term.name}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    label="Naviera"
                    name="shippingLine"
                    value={values.shippingLine}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.shippingLine && Boolean(errors.shippingLine)}
                    helperText={touched.shippingLine && errors.shippingLine}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    label="Cantidad/Tipo de piezas o Contenedores"
                    name="quantity"
                    value={values.quantity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.quantity && Boolean(errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    select
                    label="Agente asignado"
                    name="assignedAgent"
                    value={values.assignedAgent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.assignedAgent && Boolean(errors.assignedAgent)
                    }
                    helperText={touched.assignedAgent && errors.assignedAgent}
                  >
                    {agentsData?.map((load) => (
                      <MenuItem key={load.id} value={load.id}>
                        {load.name}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    label="Peso Bruto"
                    name="grossWeight"
                    value={values.grossWeight}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.grossWeight && Boolean(errors.grossWeight)}
                    helperText={touched.grossWeight && errors.grossWeight}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    label="Peso Neto"
                    name="netWeight"
                    value={values.netWeight}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.netWeight && Boolean(errors.netWeight)}
                    helperText={touched.netWeight && errors.netWeight}
                  />
                </Grid>
              </Grid>

              <SectionTitle title="Tarifas" />
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    label="Tarifas NETAS"
                    name="netRate"
                    value={values.netRate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.netRate && Boolean(errors.netRate)}
                    helperText={touched.netRate && errors.netRate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    label="Tarifas VENTAS"
                    name="saleRate"
                    value={values.saleRate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.saleRate && Boolean(errors.saleRate)}
                    helperText={touched.saleRate && errors.saleRate}
                  />
                </Grid>
              </Grid>

              <SectionTitle title="Características Especiales" />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isInsured"
                        checked={values.isInsured}
                        onChange={handleChange}
                      />
                    }
                    label="¿Se asegura la carga?"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="temperature"
                        checked={values.temperature}
                        onChange={handleChange}
                      />
                    }
                    label="Temperatura controlada"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="dangerous"
                        checked={values.dangerous}
                        onChange={handleChange}
                      />
                    }
                    label="Mercancía peligrosa"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="nonStackable"
                        checked={values.nonStackable}
                        onChange={handleChange}
                      />
                    }
                    label="Mercancía No apilable"
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  gridColumn: "span 2",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <SectionTitle title="Adjuntos" />
                <DropZone files={files} addFiles={addFiles} />
              </div>

              <Grid item xs={12}>
                <SectionTitle title="Comentarios adicionales" />
                <StyledTextField
                  fullWidth
                  label="Comentarios Adicionales"
                  name="additionalComments"
                  value={values.additionalComments}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.additionalComments &&
                    Boolean(errors.additionalComments)
                  }
                  helperText={
                    touched.additionalComments && errors.additionalComments
                  }
                  multiline
                  rows={3}
                />
              </Grid>

              <Box sx={{ textAlign: "end", mt: 4 }}>
                <Button
                  text="Enviar"
                  bold
                  bgColor={"primary_300"}
                  textColor="neutral_50"
                  type="submit"
                  disabled={isSubmitting}
                ></Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
      <NewOrganizationModal
        open={openModal}
        onClose={handleCloseModal}
        onSubmit={handleCreateOrganization}
      />
    </StyledPaper>
  );
};

export default TransportForm;
