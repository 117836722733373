import {styled,Modal } from "@nextui-org/react";
import theme from "../../../../theme";

export const ExcelButton = styled("button", {
    display: "flex",
    background: "white",
    color: "$excel",
    gap: "5px",
    borderRadius: "8px",
    padding: "10px",
    transition: "300ms",
    border: "1px solid $excel",
    justifyContent: "center",
    ["&:hover"]: {
      cursor: "pointer",
      background: "$neutral_100",
    },
  });
  
  export const StyledLoad = styled("div", {
    [`.${theme} &`]: {
      boxShadow:'$elevation_1',
      margin: "3rem 8rem 0rem 8rem",
      padding: "3rem 8rem",
      backgroundColor: "$neutral_50",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      ".header": {
        display: "flex",
        justifyContent: "space-between",
        color: "$primary_300",
        fontSize: "18px",
        div: { display: "flex", gap: "10px", alignItems: "center" },
      },
      ["aside"]: {
        color: "$primary_200",
      },
      ["main"]: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      },
      [".separate"]: {
        margin: "0rem 1rem",
      },
      [".form"]: {
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(200px, 1fr))",
        gap: "0.5rem",
      },
      [".inputContainer"]: {
        [".nextui-input-main-container"]: {
          width: "100%",
          ["label"]: {
            color: "$primary_500",
          },
        },
        [".nextui-input-content--right"]: {
          width: "fit-content",
          padding: "0rem",
          [".nextui-dropdown-button"]: {
            height: "100%",
          },
        },
      },
      [".inputDropdown"]: {
        borderRadius: "8px",
        backgroundColor: "white",
        border: "solid 1px $primary_300",
        transition: "border 0.25s ease",
        marginRight: 0,
        "&:focus-within": {
          border: `solid 1px $primary_100`,
        },
        ".nextui-input-wrapper": {
          borderRadius: "8px",
          backgroundColor: "$neutral_50",
        },
        ".nextui-input-label--right": {
          borderRadius: "0px 8px 8px 0px",
          backgroundColor: "$neutral_100",
        },
      },
      [".cardUnit"]: {
        padding: "0rem 1.25rem",
        backgroundColor: "$neutral_100",
        height: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        color: "$primary_500",
        fontSize: "0.875rem",
      },
      [`.form-container`]: {
        padding: "10px",
        marginBottom: "10px",
      },
  
      [`.minimized-forms`]: {
        display: "flex",
        flexWrap: "wrap",
      },
      [`.minimized-form-container`]: {
        flexBasis: "calc(100% / 8)",
      },
      [".load-info"]: {
        display: "flex",
        justifyContent: "space-around",
        margin: "15px",
        fontSize:'13px'
      },
    },
    "@media (max-width: 1200px)":{
      [`.${theme} &`]:{
        margin:"1rem 2rem",
        padding:"2rem",
        fontSize:'12px',
      },
    },
    "@media (max-width: 900px)":{
      [`.${theme} &`]:{
        margin:"1rem",
        padding:"1rem",
      },
    },
    "@media (max-width: 550px)":{
      [`.${theme} &`]:{
        [".form"]: {
          display: "grid",
          gridTemplateColumns: "auto",
        },
        [".header"]: {
          flexDirection:'column',
          gap:'20px',
        },
        [".load-info"]: {
          flexDirection:'column',
          margin: "15px",
        },
      },
    },
  });
  export const Paginado = styled("div", {
    display: "flex",
    gap: "10px",
  });

  export const StyledModal = styled(Modal, {
    color: "$primary_500 !important",
    padding: "20px",
  });