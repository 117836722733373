import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
} from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import Button from '../../../components/Button/Button';
import {
  Person,
  Home,
  Language,
  Phone,
  Email,
  Badge,
  Business,
} from '@mui/icons-material';

const StyledTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '& fieldset': {
      borderColor: '#3D509A',
    },
    '&:hover fieldset': {
      borderColor: '#3D509A',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3D509A',
    },
  },
}));

const validationSchema = Yup.object({
  name: Yup.string().required('Requerido'),
  delivery_address: Yup.string().required('Requerido'),
  website: Yup.string().url('Debe ser una URL válida'),
  phone: Yup.string().required('Requerido'),
  email: Yup.string().email('Email inválido').required('Requerido'),
  identification_number: Yup.string().required('Requerido'),
  organization_types: Yup.array().min(1, 'Seleccione al menos un tipo de organización'),
});

const NewOrganizationModal = ({ open, onClose, onSubmit }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Business fontSize="large" style={{ marginRight: '10px', verticalAlign: 'middle' }} />
        Crear nueva organización
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: '',
            delivery_address: '',
            website: '',
            phone: '',
            email: '',
            identification_number: '',
            organization_types: [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleBlur, errors, touched, isSubmitting, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                {[
                  { name: 'name', label: 'Nombre', icon: <Person /> },
                  { name: 'delivery_address', label: 'Dirección de entrega', icon: <Home /> },
                  { name: 'website', label: 'Sitio web', icon: <Language /> },
                  { name: 'phone', label: 'Teléfono', icon: <Phone /> },
                  { name: 'email', label: 'Correo electrónico', icon: <Email /> },
                  { name: 'identification_number', label: 'Número de identificación', icon: <Badge /> },
                ].map((field) => (
                  <Grid item xs={12} key={field.name}>
                    <StyledTextField
                      fullWidth
                      label={field.label}
                      name={field.name}
                      value={values[field.name]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched[field.name] && Boolean(errors[field.name])}
                      helperText={touched[field.name] && errors[field.name]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {field.icon}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.organization_types.includes('shipper')}
                          onChange={(e) => {
                            const newTypes = e.target.checked
                              ? [...values.organization_types, 'shipper']
                              : values.organization_types.filter((t) => t !== 'shipper');
                            setFieldValue('organization_types', newTypes);
                          }}
                        />
                      }
                      label="Shipper"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.organization_types.includes('consignee')}
                          onChange={(e) => {
                            const newTypes = e.target.checked
                              ? [...values.organization_types, 'consignee']
                              : values.organization_types.filter((t) => t !== 'consignee');
                            setFieldValue('organization_types', newTypes);
                          }}
                        />
                      }
                      label="Consignee"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.organization_types.includes('notify')}
                          onChange={(e) => {
                            const newTypes = e.target.checked
                              ? [...values.organization_types, 'notify']
                              : values.organization_types.filter((t) => t !== 'notify');
                            setFieldValue('organization_types', newTypes);
                          }}
                        />
                      }
                      label="Notify"
                    />
                  </FormGroup>
                  {touched.organization_types && errors.organization_types && (
                    <div style={{ color: 'red', fontSize: '0.75rem' }}>{errors.organization_types}</div>
                  )}
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  onClick={onClose}
                  text="Cancelar"
                  bgColor="neutral_200"
                  textColor="neutral_900"
                />
                <Button
                  type="submit"
                  text="Crear"
                  bold
                  bgColor="primary_300"
                  textColor="neutral_50"
                  disabled={isSubmitting}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default NewOrganizationModal;