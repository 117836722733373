import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import { useSelector } from "react-redux";
import {
  deleteDocument,
  downloadDocument,
  getDocuments,
  handleDownloadDocument,
} from "../../../../../services/documents.services";
import PdfIcon from "../../../../../assets/Files/pdf.png";
import DocIcon from "../../../../../assets/Files/word.png";
import XlsIcon from "../../../../../assets/Files/excel.png";
import ZipIcon from "../../../../../assets/Files/zip.png";
import toast from "../../../../../components/Toast/ToastTypes";
import { useTranslation } from "react-i18next";
import DeleteConfirmationModal from "../../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import { Modal, Button, Dropdown } from "@nextui-org/react";
import PDFPreviewModal from "./PDFPreviewModal"; // Make sure to adjust the import path as needed

const StyledAttachments = styled("div", {
  position: "relative",
  ".deleteButton": {
    borderRadius: "7px",
    padding: "2px 8px",
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "$delete",
    color: "white",
    transition: "300ms",
    cursor: "pointer",
    zIndex: "100",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  [".container"]: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 200px))",
    gridGap: "1rem",
    [".card"]: {
      position: "relative",
      cursor: "pointer",
      borderRadius: "8px",
      border: "1px solid $secondary_300",
      backgroundColor: "$neutral_50",
      overflow: "hidden",
      [".file"]: {
        padding: "1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "150px",
        ["img"]: {
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        },
      },
      [".title"]: {
        backgroundColor: "$secondary_50",
        alignSelf: "end",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "0.5rem 1rem",
        fontSize: "0.9rem",
        ["span"]: {
          width: "100%",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
        ["span.type"]: {
          color: "$secondary_300",
        },
      },
    },
  },
  ".menu-button": {
    position: "absolute",
    top: "5px",
    right: "5px",
    zIndex: 100,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    borderRadius: "50%",
    padding: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
  },
});

const Attachments = ({ radication }) => {
  const token = useSelector((state) => state.user.token);
  const { t: translate } = useTranslation("Services");

  const [documents, setDocuments] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const download = (document) => {
    downloadDocument(document.route, token)
      .then((res) => {
        !res.ok &&
          toast("error", "Hubo un error al intentar descargar el documento");
        return res.json();
      })
      .then((data) => {
        handleDownloadDocument(data.document, document.name);
      });
  };

  function getData() {
    getDocuments(radication, token).then(async (res) => {
      const data = await res.json();
      res.ok && setDocuments(data);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  function getFileIconAndType(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();
    let IconComponent;
    let fileType;

    switch (extension) {
      case "pdf":
        IconComponent = PdfIcon;
        fileType = "PDF";
        break;
      case "doc":
      case "docx":
        IconComponent = DocIcon;
        fileType = "DOC";
        break;
      case "jpeg":
      case "jpg":
      case "png":
        IconComponent = null;
        fileType = "IMAGE";
        break;
      case "zip":
        IconComponent = ZipIcon;
        fileType = "ZIP";
        break;
      case "xls":
      case "xlsx":
        IconComponent = XlsIcon;
        fileType = "XLS";
        break;
      default:
        IconComponent = PdfIcon;
        fileType = "FILE";
    }
    return { IconComponent, fileType };
  }

  function handleDelete(document) {
    deleteDocument(token, radication, document.id).then((res) => {
      getData();
      setDeleteModalOpen(false);
    });
  }

  const renderMenu = (document) => {
    const { fileType } = getFileIconAndType(document.name);
    const menuItems = [
      { key: "download", name: "Descargar" },
      { key: "delete", name: "Eliminar" },
    ];

    if (fileType === "PDF" || fileType === "IMAGE") {
      menuItems.unshift({ key: "preview", name: "Vista previa" });
    }

    return (
      <Dropdown>
        <Dropdown.Button light className="menu-button">
          •••
        </Dropdown.Button>
        <Dropdown.Menu
          onAction={(key) => handleMenuAction(key, document)}
          items={menuItems}
        >
          {(item) => <Dropdown.Item key={item.key}>{item.name}</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleMenuAction = (key, document) => {
    switch (key) {
      case "preview":
        if (getFileIconAndType(document.name).fileType === "PDF") {
          setPdfPreviewUrl(document.signed_url);
        } else {
          setImagePreviewUrl(document.signed_url);
        }
        break;
      case "download":
        download(document);
        break;
      case "delete":
        setDocumentToDelete(document);
        setDeleteModalOpen(true);
        break;
    }
  };

  return (
    <StyledAttachments>
      <h5>
        {translate("total_attachments")}: {documents.length}
      </h5>
      <div className="container">
        {documents.map((document, i) => {
          const { IconComponent, fileType } = getFileIconAndType(document.name);
          const isImage = fileType === "IMAGE";
          return (
            <div key={i} className="card">
              {renderMenu(document)}
              <div className="file">
                {isImage ? (
                  <img src={document.signed_url} alt={document.name} />
                ) : (
                  <img src={IconComponent} alt={fileType} />
                )}
              </div>
              <div className="title">
                <span className="name">{document.name}</span>
                <span className="type">{fileType}</span>
              </div>
            </div>
          );
        })}
      </div>
      <DeleteConfirmationModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        text={"¿Estás seguro de que quieres borrar este documento?"}
        handleDelete={() => handleDelete(documentToDelete)}
      />
      <PDFPreviewModal
        isOpen={!!pdfPreviewUrl}
        onClose={() => setPdfPreviewUrl(null)}
        url={pdfPreviewUrl}
      />
      <Modal open={!!imagePreviewUrl} onClose={() => setImagePreviewUrl(null)}>
        <Modal.Body>
          <img
            src={imagePreviewUrl}
            alt="Preview"
            style={{ maxWidth: "100%" }}
          />
        </Modal.Body>
      </Modal>
    </StyledAttachments>
  );
};

export default Attachments;
