import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import { useForm, Controller } from "react-hook-form";
import Input from "../../../components/Input/Input";
import Summary from "../Summary/Summary";
import Button from "../../../components/Button/Button";
import { getProfile, editProfile } from "../../../services/auth.services";
import { useSelector } from "react-redux";
import toast from "../../../components/Toast/ToastTypes";
import {
  Box,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  InputLabel,
} from "@mui/material";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PublicIcon from "@mui/icons-material/Public";
import TimezoneSelect from "react-timezone-select";
import { useTranslation } from "react-i18next";

import PhoneIcon from "@mui/icons-material/PhoneTwoTone";
import BusinessIcon from "@mui/icons-material/BusinessTwoTone";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BadgeIcon from "@mui/icons-material/BadgeTwoTone";
import TranslateIcon from "@mui/icons-material/TranslateTwoTone";

const StyledInformation = styled("div", {
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  [".inputs"]: {
    marginTop: "1rem",
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
    gridAutoFlow: "dense",
    width: "100%",
  },
});
const StyledForm = styled("form", {
  backgroundColor: "$neutral_50",
  borderRadius: "8px",
  padding: "2rem",
  gap: "1rem",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "@media (max-width: 768px)": {
    flexWrap: "wrap",
  },
  ".footer": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
  },
});

const Information = ({ user }) => {
  const token = useSelector((state) => state.user.token);
  const { handleSubmit, control, reset } = useForm();
  const [profileData, setProfileData] = useState();
  const [disableForm, setDisableForm] = useState(true);
  const { t: translate } = useTranslation("Profile");

  const currencies = [
    { value: "USD", label: "USD $" },
    { value: "COP", label: "COL $" },
    { value: "EUR", label: "EUR €" },
    { value: "MXN", label: "MXN $" },
  ];

  function getData() {
    getProfile(token)
      .then(async (res) => {
        const data = await res.json();
        setProfileData(data);
        reset({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone,
          language: data.language,
          timezone: data.timezone,
          currency: user.currency,
          organization: data.organization.name,
          website: data.organization.website,
          last_login: data.last_login,
        });
      })
      .catch((err) => console.log("err :", err));
  }

  useEffect(() => {
    getData();
  }, [user]);

  function onSubmit(data) {
    const submitData = { ...profileData, ...data };
    editProfile(token, submitData)
      .then((res) => {
        toast("success", "Perfil modificado con exito");
        getData();
        setDisableForm(true);
      })
      .catch((err) => {
        toast("error", "Hubo un error");
      });

      if (data.language !== i18n.language) {
        i18n.changeLanguage(data.language);
      }
  }

  return (
    <StyledInformation className="theme">
      <Summary user={user} />
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <div className="header">
          <span className="bold">{translate("contact_data")}</span>
          <Button
            text={translate("modify")}
            bold
            bgColor={"primary_300"}
            textColor="neutral_50"
            handler={() => setDisableForm(false)}
          />
        </div>
        <div className="inputs">
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <TextField
                label={translate("name")}
                {...field}
                disabled={disableForm}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BadgeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <TextField
                label={translate("lastname")}
                {...field}
                disabled={disableForm}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BadgeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                label={translate("email")}
                {...field}
                disabled={disableForm}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                label={translate("telephone")}
                {...field}
                disabled={disableForm}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="organization"
            control={control}
            render={({ field }) => (
              <TextField
                label={translate("organization")}
                {...field}
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="website"
            control={control}
            render={({ field }) => (
              <TextField
                label="Website"
                {...field}
                disabled={disableForm}
                style={{ height: "50%", width: "60%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">https://</InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="language"
            control={control}
            defaultValue="es_CO"
            render={({ field }) => (
              <FormControl
                style={{ height: "50%", width: "60%" }}
                disabled={disableForm}
              >
                <InputLabel id="language-select-label">{translate("language")}</InputLabel>
                <Select
                  labelId="language-select-label"
                  {...field}
                  value={field.value || 'es_CO'}
                  startAdornment={
                    <InputAdornment position="start">
                      <TranslateIcon />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="es_CO">Español (Colombia)</MenuItem>
                  <MenuItem value="en_US">English (United States)</MenuItem>
                </Select>
              </FormControl>
            )}
          />

          <Controller
            name="timezone"
            control={control}
            defaultValue="ETC/GMT-5"
            render={({ field }) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InputAdornment position="start">
                  <PublicIcon />
                </InputAdornment>
                <Box sx={{ flexGrow: 1 }}>
                  <TimezoneSelect
                    value={field.value || "ETC/GMT-5"}
                    onChange={(selectedOption) =>
                      field.onChange(
                        selectedOption ? selectedOption.value : "ETC/GMT-5"
                      )
                    }
                    disabled={disableForm}
                  />
                </Box>
              </Box>
            )}
          />
          <Controller
            name="currency"
            control={control}
            defaultValue="USD"
            render={({ field }) => (
              <TextField
                {...field}
                id="outlined-select-currency"
                select
                label={translate("currency")}
                disabled={disableForm}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
                helperText={translate("select_currency")}
                value={field.value || 'USD'}
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <div className="last-login">
            <span className="bold">{translate("last_login")}</span>
            <span>{user.last_login}</span>
          </div>
        </div>
        <div className="footer">
          <Button
            text={translate("cancel")}
            bold
            bgColor={"primary_300"}
            textColor="neutral_50"
            disabled={disableForm}
            handler={() => {
              reset({
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
                language: user.language,
                timezone: user.timezone,
                currency: user.currency,
                organization: user.organization,
                last_login: user.last_login,
              });
              setDisableForm(true);
            }}
          />
          <Button
            text={translate("save")}
            bold
            bgColor={"primary_300"}
            textColor="neutral_50"
            disabled={disableForm}
            type="submit"
          />
        </div>
      </StyledForm>
    </StyledInformation>
  );
};

export default Information;
