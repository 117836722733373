import React, { useEffect, useState, useCallback } from 'react';
import { Loading, Modal, styled } from '@nextui-org/react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import toast from '../../../../components/Toast/ToastTypes';
import Button from '../../../../components/Button/Button';
import FormInputSelect from '../../../../components/FormInputSelect/FormInputSelect';
import FormInput from '../../../../components/FormInput/FormInput';
import { register } from '../../../../services/auth.services';
import { getIndustries } from '../../../../services/industries.services';
import Icon from '../../../../components/Icon/Icon';

const StyledModal = styled(Modal, {
    padding: '1.5rem',
    gap: '20px',
    color: '$primary_500 !important',
    "@media (max-width : 600px)": {
        padding: '0px',
    }
});

const documentTypes = [
    { label: "CC", value: "CC" },
    { label: "RFC", value: "RFC" },
    { label: "NIT", value: "NIT" }
];

const InputsContainer = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '20px',
    marginBottom: '30px',
    "@media (max-width : 600px)": {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    }
});

const generateRandomPassword = () => {
    const array = new Uint8Array(12);
    window.crypto.getRandomValues(array);
    return btoa(String.fromCharCode(...array));
};

export default function RegisterModal({ open, setOpen, getData }) {
    const token = useSelector(state => state.user.token);
    const { handleSubmit, reset, ...methods } = useForm();

    const [industries, setIndustries] = useState([]);
    const [loading, setLoading] = useState(false);

    const onSubmit = useCallback(async (formData) => {
        setLoading(true);
        const randomPassword = generateRandomPassword();
        const submitData = {
            ...formData,
            usr: true,
            password1: randomPassword,
            password2: randomPassword,
            document_type: formData.document_type.value,
            industry: formData.industry?.label,
            organization: {
                name: formData.industry?.label,
                industry: formData.industry?.value,
                website: 'N',
            },
        };

        try {
            const res = await register(submitData);
            if (res.status === 204) {
                toast("success", "Cliente creado con éxito");
                if (getData) {
                    getData();
                }
                reset();
                setOpen(false);
            } else {
                Object.keys(res).forEach(key => {
                    const error = res[key];
                    if (error[0] === "A user is already registered with this e-mail address.") {
                        toast("error", "Este correo ya está en uso");
                    } else {
                        toast("error", "Hubo un error en el registro");
                    }
                });
            }
        } catch (err) {
            toast("error", "Hubo un error en el registro");
        } finally {
            setLoading(false);
        }
    }, [getData, reset, setOpen]);

    useEffect(() => {
        const fetchIndustries = async () => {
            try {
                const res = await getIndustries(token);
                const industriesData = await res.json();
                setIndustries(industriesData);
            } catch (error) {
                console.error("Error fetching industries:", error);
                toast("error", "Error al cargar las industrias");
            }
        };

        fetchIndustries();
    }, [token]);

    return (
        <StyledModal
            closeButton
            aria-labelledby="register-modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >  
            <Modal.Body justify='space-between'>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div style={{display:'flex', justifyContent:'space-between', margin:'20px 0', borderBottom:' 2px dashed #292E68'}}>
                            <h3 id="register-modal-title">Registro de cliente</h3>
                            <Icon name='UserAdd' size='35px'/>
                        </div>
                        
                        <InputsContainer>
                            <FormInput name="first_name" label="Nombre" required icon='UserAdd'/>
                            <FormInput name="last_name" label="Apellido" required icon='UserAdd'/>
                            <FormInputSelect
                                required
                                name="document_type"
                                label="Tipo de documento"
                                options={documentTypes}
                            />
                            <FormInput name="document" label="Documento" required icon={'Cotizaciones'}/>
                            <FormInput name="phone" label="Teléfono" required icon={'Phone'}/>
                            <FormInputSelect
                                required
                                name="industry"
                                label="Industria"
                                options={industries?.map(service => ({
                                    label: service.name,
                                    value: service?.id,
                                }))}
                            />
                            <div style={{gridColumn:'span 2'}}>
                                <FormInput name="email" label="Correo" required icon={'Mail'}/>
                            </div>
                        </InputsContainer>
                        <Button 
                            type="submit" 
                            text={loading ? <Loading /> : "Añadir Cliente"} 
                            bgColor={'primary_500'} 
                            textColor={'neutral_50'} 
                            width="100%" 
                            disabled={loading}
                        />
                    </form>
                </FormProvider>
            </Modal.Body>
        </StyledModal>
    );
}