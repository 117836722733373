import React from 'react';
import styles from './Co2Chart.module.scss';

export default function Co2Chart({ value, max = 1000 }) {
  // Limitar el valor entre 0 y el máximo especificado
  // Calcular el porcentaje basado en el valor y el máximo
  const percentage = (value / max) * 100;
  let limitedPercentage = Math.min(percentage, 90);
  limitedPercentage = Math.max(limitedPercentage, 20);
  // Calcular la posición del agua en función del porcentaje
  const waterStyle = {
    top: `${100 - limitedPercentage}%`
  };
  const waterStyle2 = {
    top: `${100 - limitedPercentage/2}%`
  };

  return (
    <div className={styles.fuProgress}>
      <div className={styles.fuInner}>
        <div className={styles.fuPercent}>{value} Kg Co₂e</div>
        <div className={styles.water} style={waterStyle}></div>
        <div className={styles.water2} style={waterStyle2}></div>
        <div className={styles.glare}></div>
      </div>
    </div>
  );
}

