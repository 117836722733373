import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
  Paper,
  Typography,
  Grid,
  Divider,
  Box,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  Button,
  Snackbar,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  AttachFile as AttachFileIcon,
  ContentCopy as ContentCopyIcon,
  PictureAsPdf as PdfIcon,
} from "@mui/icons-material";
import { usePDF } from "react-to-pdf";

const SectionTitle = ({ title }) => (
  <Box sx={{ mt: 3, mb: 2 }}>
    <Typography variant="h6" sx={{ color: "#292e68", fontWeight: "bold" }}>
      {title}
    </Typography>
    <Divider sx={{ mt: 1 }} />
  </Box>
);

const InfoItem = ({ label, value }) => (
  <Box sx={{ mb: 1 }}>
    <Typography variant="body2" color="text.secondary">
      {label}
    </Typography>
    <Typography variant="body1">{value || "N/A"}</Typography>
  </Box>
);

const CompactPDFContent = ({ data }) => (
  <div style={{ padding: "16px", fontSize: "10px", width: "210mm", minHeight: "297mm", backgroundColor: "white" }}>
    <h2 style={{ marginBottom: "16px", fontWeight: "bold" }}>Resumen de Instrucciones de Envío</h2>
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "8px" }}>
      <div>
        <h3 style={{ fontWeight: "bold" }}>Información General</h3>
        <p>Shipper: {data.shipper}</p>
        <p>Consignee: {data.consignee}</p>
        <p>Notify: {data.notify}</p>
      </div>
      <div>
        <h3 style={{ fontWeight: "bold" }}>Detalles de la Mercancía</h3>
        <p>Descripción: {data.description}</p>
        <p>Tipo de Carga: {data.cargoType}</p>
        <p>Contenedor: {data.containerType}</p>
      </div>
      <div>
        <h3 style={{ fontWeight: "bold" }}>Origen</h3>
        <p>{data.origin.country}: {data.origin.address}</p>
        <p>Info Adicional: {data.origin.additionalInfo}</p>
      </div>
      <div>
        <h3 style={{ fontWeight: "bold" }}>Destino</h3>
        <p>{data.destination.country}: {data.destination.address}</p>
        <p>Info Adicional: {data.destination.additionalInfo}</p>
      </div>
      <div>
        <h3 style={{ fontWeight: "bold" }}>Información Adicional</h3>
        <p>Incoterm: {data.incoterm}</p>
        <p>Naviera: {data.shippingLine}</p>
        <p>Cantidad/Tipo: {data.quantity}</p>
        <p>Agente: {data.assignedAgent}</p>
      </div>
      <div>
        <h3 style={{ fontWeight: "bold" }}>Tarifas</h3>
        <p>NETAS: {data.netRate}</p>
        <p>VENTAS: {data.saleRate}</p>
      </div>
    </div>
    <div>
      <h3 style={{ fontWeight: "bold" }}>Peso y Medidas</h3>
      <p>Peso Bruto: {data.grossWeight}</p>
      <p>Peso Neto: {data.netWeight}</p>
    </div>
    <div>
      <h3 style={{ fontWeight: "bold" }}>Características Especiales</h3>
      <p>
        Asegurada: {data.isInsured ? "Sí" : "No"} | Temp. Controlada: {data.temperature ? "Sí" : "No"} | 
        Peligrosa: {data.dangerous ? "Sí" : "No"} | No Apilable: {data.nonStackable ? "Sí" : "No"}
      </p>
    </div>
    <div>
      <h3 style={{ fontWeight: "bold" }}>Adjuntos</h3>
      {data.attachments.map((file, index) => (
        <p key={index}>{file.name} ({file.size})</p>
      ))}
    </div>
    <div>
      <h3 style={{ fontWeight: "bold" }}>Comentarios</h3>
      <p>{data.additionalComments || "Sin comentarios adicionales"}</p>
    </div>
  </div>
);

const ShippingInstructionsSummary = ( {shipping_instructions}  ) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);
  const { toPDF, targetRef } = usePDF({
    filename: "shipping_instructions.pdf",
    page: { format: "A4" },
  });
  
  const [data, setData] = useState(null);

  useEffect(() => {
    if (shipping_instructions) {
      try {
        let parsedData;
        if (typeof shipping_instructions === 'string') {
          parsedData = JSON.parse(shipping_instructions);
        } else if (typeof shipping_instructions === 'object') {
          parsedData = shipping_instructions;
        } else {
          throw new Error('Invalid data format');
        }
        parsedData.origin = parsedData.origin || {};
        parsedData.destination = parsedData.destination || {};
        setData(parsedData);
      } catch (error) {
        console.error("Error parsing shipping_instructions:", error);
      }
    }
  }, [shipping_instructions]);

  if (!data) {
    return <Typography>Loading...</Typography>;
  }

const generatePlainText = () => {
    const text = `
RESUMEN DE INSTRUCCIONES DE ENVÍO

INFORMACIÓN GENERAL
Shipper: ${data.shipper}
Consignee: ${data.consignee}
Notify: ${data.notify}

DETALLES DE LA MERCANCÍA
Descripción: ${data.description}
Tipo de Carga: ${data.cargoType}
Contenedor: ${data.containerType}

ORIGEN
País: ${data.origin.country}
Dirección: ${data.origin.address}
Información Adicional: ${data.origin.additionalInfo}

DESTINO
País: ${data.destination.country}
Dirección: ${data.destination.address}
Información Adicional: ${data.destination.additionalInfo}

INFORMACIÓN ADICIONAL
Incoterm: ${data.incoterm}
Naviera: ${data.shippingLine}
Cantidad/Tipo: ${data.quantity}
Agente asignado: ${data.assignedAgent}

TARIFAS
Tarifas NETAS: ${data.netRate}
Tarifas VENTAS: ${data.saleRate}

PESO Y MEDIDAS
Peso Bruto: ${data.grossWeight}
Peso Neto: ${data.netWeight}

CARACTERÍSTICAS ESPECIALES
¿Se asegura la carga?: ${data.isInsured ? "Sí" : "No"}
Temperatura controlada: ${data.temperature ? "Sí" : "No"}
Mercancía peligrosa: ${data.dangerous ? "Sí" : "No"}
Mercancía No apilable: ${data.nonStackable ? "Sí" : "No"}

ADJUNTOS
${data.attachments.map((file) => `${file.name} (${file.size})`).join("\n")}

COMENTARIOS ADICIONALES
${data.additionalComments || "Sin comentarios adicionales"}
    `.trim();

    return text;
  };

  const copyToClipboard = () => {
    const text = generatePlainText();
    navigator.clipboard.writeText(text).then(() => {
      setOpenSnackbar(true);
    });
  };

  const handleExportPDF = async () => {
    setPdfPreviewOpen(true);
    setTimeout(() => {
      toPDF();
      setPdfPreviewOpen(false);
    }, 100);
  };

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 1400, my: 4, borderRadius: 2, bgcolor: "#f5f5f5" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 4 }}>
        <Typography variant="h4" sx={{ color: "#292e68", fontWeight: "bold" }}>
          Resumen de Instrucciones de Envío
        </Typography>
        <Box>
          <Button variant="contained" startIcon={<ContentCopyIcon />} onClick={copyToClipboard} sx={{ mr: 2 }}>
            Copiar como texto
          </Button>
          <Button variant="contained" startIcon={<PdfIcon />} onClick={handleExportPDF}>
            Exportar PDF
          </Button>
        </Box>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card elevation={2}>
            <CardContent>
              <SectionTitle title="Información General" />
              <InfoItem label="Shipper" value={data.shipper} />
              <InfoItem label="Consignee" value={data.consignee} />
              <InfoItem label="Notify" value={data.notify} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card elevation={2}>
            <CardContent>
              <SectionTitle title="Detalles de la Mercancía" />
              <InfoItem label="Descripción" value={data.description} />
              <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                <Chip label={`Tipo de Carga: ${data.cargoType}`} color="primary" />
                <Chip label={`Contenedor: ${data.containerType}`} color="secondary" />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card elevation={2}>
            <CardContent>
              <SectionTitle title="Origen" />
              <InfoItem label="País" value={data.origin.country } />
              <InfoItem label="Dirección" value={data.origin.address} />
              <InfoItem label="Información Adicional" value={data.origin.additionalInfo} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card elevation={2}>
            <CardContent>
              <SectionTitle title="Destino" />
              <InfoItem label="País" value={data.destination.country} />
              <InfoItem label="Dirección" value={data.destination.address} />
              <InfoItem label="Información Adicional" value={data.destination.additionalInfo} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card elevation={2}>
            <CardContent>
              <SectionTitle title="Información Adicional" />
              <List dense>
                <ListItem>
                  <ListItemText primary="Incoterm" secondary={data.incoterm} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Naviera" secondary={data.shippingLine} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Cantidad/Tipo" secondary={data.quantity} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Agente asignado" secondary={data.assignedAgent} />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card elevation={2}>
                <CardContent>
                  <SectionTitle title="Tarifas" />
                  <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="h6" color="primary">Tarifas NETAS</Typography>
                      <Typography variant="h4">{data.netRate}</Typography>
                    </Box>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="h6" color="secondary">Tarifas VENTAS</Typography>
                      <Typography variant="h4">{data.saleRate}</Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={2}>
                <CardContent>
                  <SectionTitle title="Peso y Medidas" />
                  <InfoItem label="Peso Bruto" value={data.grossWeight} />
                  <InfoItem label="Peso Neto" value={data.netWeight} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card elevation={2}>
            <CardContent>
              <SectionTitle title="Características Especiales" />
              <Grid container spacing={2}>
                {[
                  { label: "¿Se asegura la carga?", value: data.isInsured },
                  { label: "Temperatura controlada", value: data.temperature },
                  { label: "Mercancía peligrosa", value: data.dangerous },
                  { label: "Mercancía No apilable", value: data.nonStackable },
                ].map((item, index) => (
                  <Grid item xs={6} sm={3} key={index}>
                    <Chip
                      label={item.label}
                      color={item.value ? "success" : "default"}
                      variant={item.value ? "filled" : "outlined"}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card elevation={2}>
            <CardContent>
              <SectionTitle title="Adjuntos" />
              <List dense>
                {data.attachments.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <AttachFileIcon />
                    </ListItemIcon>
                    <ListItemText primary={file.name} secondary={file.size} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card elevation={2}>
            <CardContent>
              <SectionTitle title="Comentarios Adicionales" />
              <Typography variant="body1" sx={{ fontStyle: "italic", mt: 1 }}>
                "{data.additionalComments || "Sin comentarios adicionales"}"
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={pdfPreviewOpen}
        onClose={() => setPdfPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <div ref={targetRef}>
            <CompactPDFContent data={data} />
          </div>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message="Texto copiado al portapapeles"
      />
    </Paper>
  );
};

export default ShippingInstructionsSummary;
