import { URL } from "../constants"


export const getShippingBillOrganization = async (token) => {
    // console.log("Token in getShippingBillOrganization:", token); // Debug log
    const response = await fetch(`${URL.get()}shippingbill/organization/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}` // Ensure correct format
        }
    });
    // console.log("Response from getShippingBillOrganization:", response); // Debug log
    return response;
}

export const getShippingBillOrgType = async (token) => {
    // console.log("Token in getShippingBillOrgType:", token); // Debug log
    const response = await fetch(`${URL.get()}shippingbill/typeorganization/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}` // Ensure correct format
        }
    });
    // console.log("Response from getShippingBillOrgType:", response); // Debug log
    return response;
}



