import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@nextui-org/react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Icon from "../../Icon/Icon";
import theme from "../../../theme";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx";

const ChartContainer = styled("div", {
  padding: "20px",
  height: "500px",
});

const TitleContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "18x",
});

const TitleIconContainer = styled("div", {
  display: "flex",
  gap: "10px",
  alignItems: "center",
});

const Title = styled("p", {
  color: theme.colors.primary_300,
  fontWeight: "800",
  fontSize: "1.2rem",
});

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        <p>{label}</p>
        {payload.map((data, index) => (
          <p key={index} style={{ color: data.fill }}>{`${
            data.name
          }: $${data.value.toLocaleString()} USD`}</p>
        ))}
      </div>
    );
  }
  return null;
};

export default function SimpleBarChart({ data, title, icon }) {
  const { t: translate } = useTranslation("Home");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatNumberWithCurrency = (value) => {
    return typeof value === "number" ? `$${value.toLocaleString()} USD` : value;
  };

  const exportToExcel = () => {
    const exportData = data.map((row) => ({
      Nombre: row.name,
      [translate("land")]: row[translate("land")],
      [translate("air")]: row[translate("air")],
      [translate("ocean")]: row[translate("ocean")],
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(wb, ws, "Datos");
    XLSX.writeFile(wb, "chart_data.xlsx");
    handleClose();
  };

  return (
    <ChartContainer>
      <TitleContainer>
        <TitleIconContainer>
          <Icon name={icon} color={theme.colors.secondary_200} />
          <Title>{title}</Title>
        </TitleIconContainer>
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            size="small"
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={exportToExcel}>
              <FileDownloadIcon sx={{ mr: 1, fontSize: 20 }} />
              Exportar a Excel
            </MenuItem>
          </Menu>
        </div>
      </TitleContainer>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 40,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeOpacity={0.435} strokeDasharray="8 10" />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis
            tickFormatter={formatNumberWithCurrency}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar
            dataKey={translate("land")}
            fill="url(#colorLand)"
            radius={[10, 10, 0, 0]}
          />
          <Bar
            dataKey={translate("air")}
            fill="url(#colorAir)"
            radius={[10, 10, 0, 0]}
          />
          <Bar
            dataKey={translate("ocean")}
            fill="url(#colorOcean)"
            radius={[10, 10, 0, 0]}
          />
          <defs>
            <linearGradient id="colorLand" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#81D393" stopOpacity={0.9} />
              <stop offset="100%" stopColor="#81D393" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="colorAir" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#1E90FF" stopOpacity={0.9} />
              <stop offset="100%" stopColor="#1E90FF" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="colorOcean" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#3D509A" stopOpacity={0.9} />
              <stop offset="100%" stopColor="#3D509A" stopOpacity={0.6} />
            </linearGradient>
          </defs>
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}
