import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Input as Inp, styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { useFormContext, Controller } from "react-hook-form";
import PlacesAutocomplete from "react-places-autocomplete";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import Input from "../../../../components/Input/Input";
import InputSelect from "../../../../components/Select/inputSelect";
import Icon from "../../../../components/Icon/Icon";
import { getPorts, getAirports } from "../../../../services/countries.services";
import { useTranslation } from "react-i18next";
import useMediaQueries from "../../../../hooks/useMediaQueries";
import ReactCountryFlag from "react-country-flag";

const StyledSend = styled("div", {
  [`.${theme} &`]: {
    margin: "3rem 5rem 0rem 5rem",
    boxShadow: "$elevation_1",
    padding: "3rem",
    backgroundColor: "$neutral_50",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    ".header": {
      display: "flex",
      justifyContent: "space-between",
      color: "$primary_300",
      fontSize: "18px",
      div: { display: "flex", gap: "10px", alignItems: "center" },
    },
    ["aside"]: {
      color: "$primary_200",
    },
    ["h5"]: {
      marginBottom: "0rem",
    },
    [".separate"]: {
      margin: "1rem 0",
    },
    [".form"]: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      gap: "20px",
    },
    [".o_input"]: {
      borderRadius: "8px",
      backgroundColor: "white",
      border: "solid 1px $primary_300",
      transition: "border 0.25s ease",
      color: "blue",
      ["&:focus-within"]: {
        border: "solid 1px $primary_100",
      },
      [".nextui-input-wrapper"]: {
        borderRadius: "8px",
        backgroundColor: "$neutral_50",
      },
      [".nextui-input-label--right"]: {
        borderRadius: "0px 8px 8px 0px",
        backgroundColor: "$neutral_100",
      },
      [".nextui-input-label--left"]: {
        borderRadius: "8px 0px 0px 8px",
      },
    },
    [".autocomplete-dropdown-container-top, .autocomplete-dropdown-container-bottom"]:
      {
        position: "absolute",
        zIndex: "1",
        width: "100%",
        [".suggestion"]: {
          border: "solid 1px $neutral_100",
          padding: "0.4rem",
          fontSize: "0.9rem",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
        [".suggestion:first-child"]: {
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
        },
        [".suggestion:last-child"]: {
          borderBottomRightRadius: "8px",
          borderBottomLeftRadius: "8px",
        },
      },
    [".autocomplete-dropdown-container-top"]: {
      bottom: "100%",
    },
  },
  "@media (max-width: 1200px)": {
    [`.${theme} &`]: {
      margin: "1rem 2rem",
      padding: "2rem",
      fontSize: "12px",
    },
  },
  "@media (max-width: 700px)": {
    [`.${theme} &`]: {
      marginTop: "3rem",
      padding: "20px",
      fontSize: "10px",
      [".header"]: {
        flexDirection: "column",
        gap: "20px",
      },
    },
  },
  "@media (max-width: 592px)": {
    [`.${theme} &`]: {
      margin: 0,
      marginTop: "30px",
      [".form"]: {
        display: "grid",
        gridTemplateColumns: "auto",
      },
    },
  },
});

const StyledLabel = styled("div", {
  [`.${theme} &`]: {
    color: `$primary_500`,
    ["span"]: {
      color: "#ff676c",
    },
  },
});

const Send = ({ countries, setDisabledContinue, shippingMethods }) => {
  const token = useSelector((state) => state.user.token);
  const { t: translate } = useTranslation("Quotation");
  const { sm, md } = useMediaQueries();
  const [isShippingMethodTerrestrial, setIsShippingMethodTerrestrial] =
    useState(false);
  const { control, getValues, setValue, watch } = useFormContext();

  const [allPorts, setAllPorts] = useState([]);
  const [allAirports, setAllAirports] = useState([]);
  const [_countries, _setCountries] = useState([]);

  const [selectedOriginCountry, setSelectedOriginCountry] = useState({});
  const [selectedDestinationCountry, setSelectedDestinationCountry] = useState(
    {}
  );
  const [selectedOriginTerminal, setSelectedOriginTerminal] = useState({});
  const [selectedDestinationTerminal, setSelectedDestinationTerminal] =
    useState({});

  const nameForm = "send";

  const incoterm = getValues("negotiation.businessTerm");
  const commerceType = getValues("commerce.commerceType");
  const shippingMethod = getValues("commerce.shippingMethod.value");

  const originAddressTerms = [1, 2];
  const destinationAddressTerms = [7, 17];

  const requireOriginAddress =
    originAddressTerms.includes(incoterm) && commerceType !== "customs";
  const requireDestinationAddress =
    destinationAddressTerms.includes(incoterm) && commerceType !== "customs";

  const renderTerminalWithFlag = (option) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ReactCountryFlag
        countryCode={option.country.slice(0, 2)}
        svg
        style={{ marginRight: "10px" }}
      />
      {option.label}
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      if (shippingMethod !== 3) {
        // 3 = TERRESTRE
        const portsRes = await getPorts(token);
        const airportsRes = await getAirports(token);
        setAllPorts(await portsRes.json());
        setAllAirports(await airportsRes.json());
        setIsShippingMethodTerrestrial(false);
      } else {
        setIsShippingMethodTerrestrial(true);
      }
    };
    fetchData();
  }, [token, shippingMethod]);

  const terminalOptions = useMemo(() => {
    if (shippingMethod === 1) {
      // Aéreo
      return allAirports.map((airport) => ({
        label: `${airport.iata_code} - ${airport.name} - ${airport.city} - ${airport.country}`,
        value: airport.name,
        country: airport.country,
      }));
    } else if ([2, 4, 5, 6].includes(shippingMethod)) {
      // Marítimo y otros
      return allPorts.map((portData) => {
        const [unloc, port] = portData;
        return {
          label: `${unloc} - ${port.name} - ${port.province} - ${port.country}`,
          value: port.name,
          country: port.country,
        };
      });
    }
    return [];
  }, [allPorts, allAirports, shippingMethod]);

  useEffect(() => {
    const data = countries.map((country) => ({
      value: country.id,
      label: country.name,
      iso: country.code || country.iso,
    }));
    _setCountries(data);
  }, [countries]);

  useEffect(() => {
    const sendFormValues = getValues("send");
    const isFormValid =
      sendFormValues?.destinationCountry &&
      sendFormValues?.destinationBoardingTerminal &&
      sendFormValues?.originCountry &&
      sendFormValues?.originBoardingTerminal &&
      (!requireDestinationAddress || sendFormValues?.deliveryAddress) &&
      (!requireOriginAddress || sendFormValues?.pickUpAddress) &&
      (isShippingMethodTerrestrial ||
        (selectedOriginCountry && selectedDestinationCountry));
    setDisabledContinue(!isFormValid);
  }, [watch()]);

  const handleTerminalChange = (isOrigin, selectedOption) => {
    console.log("handleTerminalChange called");
    const countryName = selectedOption.country;
    console.log("countryName:", countryName);
    const countryData = _countries.find(
      (country) => country.label === countryName
    );
    console.log("countryData:", countryData);

    if (_countries.length > 0 && countryData) {
      console.log("countryData is valid");
      if (isOrigin) {
        setSelectedOriginTerminal(selectedOption);
        setSelectedOriginCountry(countryData);
        setValue(`${nameForm}.originCountry`, countryData.value);
        setValue(`${nameForm}.originBoardingTerminal`, selectedOption.value);
      } else {
        setSelectedDestinationTerminal(selectedOption);
        setSelectedDestinationCountry(countryData);
        setValue(`${nameForm}.destinationCountry`, countryData.value);
        setValue(
          `${nameForm}.destinationBoardingTerminal`,
          selectedOption.value
        );
      }
    } else {
      console.log("countryData is invalid");
    }
  };

  return (
    <StyledSend>
      <main className="d-flex flex-column gap-1">
        <div className="header">
          <PageTitle>{translate("shipment-information")}</PageTitle>
          <div>
            {selectedOriginCountry && (
              <ReactCountryFlag
                countryCode={selectedOriginCountry.iso}
                svg
                style={{ marginRight: "10px" }}
              />
            )}
            <span>{selectedOriginCountry?.label}</span>
            {selectedOriginCountry && selectedDestinationCountry && (
              <Icon name="SimpleArrowRight" size="30px" color="#3D509A" />
            )}
            {selectedDestinationCountry && (
              <ReactCountryFlag
                countryCode={selectedDestinationCountry.iso}
                svg
                style={{ marginRight: "10px" }}
              />
            )}
            <span>{selectedDestinationCountry?.label}</span>
            <Icon
              size="30px"
              name={
                shippingMethod === 1
                  ? "Aereo"
                  : shippingMethod === 2
                  ? "Maritimo"
                  : shippingMethod === 3
                  ? "Terrestre"
                  : shippingMethod === 4
                  ? "Bbk"
                  : shippingMethod === 5
                  ? "Roro"
                  : shippingMethod === 6
                  ? "Containers"
                  : "Terrestre"
              }
            />
          </div>
        </div>

        <aside>
          {getValues("liquidation.loadType.label") === "Carga General"
            ? translate("shipment-information-general")
            : translate("shipment-information-project")}
        </aside>

        <h5>1 - {translate("origin_information")}</h5>
        <div className="form">
          <Controller
            control={control}
            name={`${nameForm}.originBoardingTerminal`}
            render={({ field }) =>
              shippingMethod === 3 ? (
                <PlacesAutocomplete
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  onSelect={(value) => {
                    console.log("onSelect value:", value);
                    field.onChange(value);
                    handleTerminalChange(true, {
                      label: value,
                      country: selectedOriginCountry?.iso,
                    });
                  }}
                  searchOptions={{
                    componentRestrictions: {
                      country: selectedOriginCountry?.iso,
                    },
                  }}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className="p-relative">
                      <Inp
                        label={
                          <StyledLabel>
                            {translate("boarding_terminal")} <span>*</span>
                          </StyledLabel>
                        }
                        className="o_input"
                        animated={false}
                        placeholder="Desde"
                        fullWidth="100%"
                        {...getInputProps()}
                      />
                      <div className="autocomplete-dropdown-container-bottom">
                        {suggestions.map((suggestion, i) => (
                          <div
                            key={i}
                            {...getSuggestionItemProps(suggestion, {
                              className: "suggestion",
                              style: {
                                backgroundColor: suggestion.active
                                  ? "#fafafa"
                                  : "#ffffff",
                                cursor: "pointer",
                              },
                            })}
                          >
                            <Icon name="Rastreo" size="14" />
                            <span>{suggestion.description}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              ) : (
                <InputSelect
                  options={terminalOptions}
                  value={selectedOriginTerminal}
                  onChange={(value) => handleTerminalChange(true, value)}
                  label={
                    <StyledLabel>
                      {translate("boarding_terminal")} <span>*</span>
                    </StyledLabel>
                  }
                />
              )
            }
          />

          <Controller
            name={`${nameForm}.pickUpAddress`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={
                  requireOriginAddress ? (
                    translate("pickup_address")
                  ) : (
                    <>
                      {translate("pickup_address")}{" "}
                      <small>{translate("optional")}</small>
                    </>
                  )
                }
                placeholder="Introduce dirección"
                required={requireOriginAddress}
              />
            )}
          />
          {isShippingMethodTerrestrial ? (
            <Controller
              control={control}
              name={`${nameForm}.originCountry`}
              render={({ field }) => (
                <InputSelect
                  options={_countries}
                  value={selectedOriginCountry}
                  onChange={(value) => {
                    setSelectedOriginCountry(value);
                    field.onChange(value.value);
                  }}
                  label={
                    <StyledLabel>
                      {translate("origin_country")} <span>*</span>
                    </StyledLabel>
                  }
                />
              )}
            />
          ) : (
            <div style={{ visibility: "hidden" }}>
              <Controller
                control={control}
                name={`${nameForm}.originCountry`}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={
                      <StyledLabel>
                        {translate("origin_country")} <span>*</span>
                      </StyledLabel>
                    }
                    value={selectedOriginCountry?.label || ""}
                    disabled
                  />
                )}
              />
            </div>
          )}

          <Controller
            name={`${nameForm}.pickUpComments`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={
                  <>
                    {translate("pickup_comments")}{" "}
                    <small>{translate("optional")}</small>
                  </>
                }
                placeholder="Introduce"
              />
            )}
          />
        </div>

        <hr className="separate" />

        <h5>2 - {translate("destination_info")}</h5>
        <div className="form">
          <Controller
            control={control}
            name={`${nameForm}.destinationBoardingTerminal`}
            render={({ field }) =>
              shippingMethod === 3 ? (
                <PlacesAutocomplete
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  onSelect={(value) => {
                    field.onChange(value);
                    handleTerminalChange(false, {
                      label: value,
                      country: selectedDestinationCountry?.iso,
                    });
                  }}
                  searchOptions={{
                    componentRestrictions: {
                      country: selectedDestinationCountry?.iso,
                    },
                  }}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className="p-relative">
                      <Inp
                        label={
                          <StyledLabel>
                            {translate("disembarkation")} <span>*</span>
                          </StyledLabel>
                        }
                        className="o_input"
                        animated={false}
                        placeholder="Hasta"
                        fullWidth="100%"
                        {...getInputProps()}
                      />
                      <div className="autocomplete-dropdown-container-top">
                        {suggestions.map((suggestion, i) => (
                          <div
                            key={i}
                            {...getSuggestionItemProps(suggestion, {
                              className: "suggestion",
                              style: {
                                backgroundColor: suggestion.active
                                  ? "#fafafa"
                                  : "#ffffff",
                                cursor: "pointer",
                              },
                            })}
                          >
                            <Icon name="Rastreo" size="14" />
                            <span>{suggestion.description}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              ) : (
                <InputSelect
                  options={terminalOptions}
                  value={selectedDestinationTerminal}
                  onChange={(value) => handleTerminalChange(false, value)}
                  label={
                    <StyledLabel>
                      {translate("disembarkation")} <span>*</span>
                    </StyledLabel>
                  }
                />
              )
            }
          />

          <Controller
            name={`${nameForm}.deliveryAddress`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={
                  requireDestinationAddress ? (
                    translate("destination_address")
                  ) : (
                    <>
                      {translate("destination_address")}{" "}
                      <small>{translate("optional")}</small>
                    </>
                  )
                }
                placeholder="Introduce dirección"
                required={requireDestinationAddress}
              />
            )}
          />

          {isShippingMethodTerrestrial ? (
            <Controller
              control={control}
              name={`${nameForm}.destinationCountry`}
              render={({ field }) => (
                <InputSelect
                  options={_countries}
                  value={selectedDestinationCountry}
                  onChange={(value) => {
                    setSelectedDestinationCountry(value);
                    field.onChange(value.value);
                  }}
                  label={
                    <StyledLabel>
                      {translate("destination_country")} <span>*</span>
                    </StyledLabel>
                  }
                />
              )}
            />
          ) : (
            <div style={{ visibility: "hidden" }}>
              <Controller
                control={control}
                name={`${nameForm}.destinationCountry`}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={
                      <StyledLabel>
                        {translate("destination_country")} <span>*</span>
                      </StyledLabel>
                    }
                    value={selectedDestinationCountry?.label || ""}
                    disabled
                  />
                )}
              />
            </div>
          )}
          <Controller
            name={`${nameForm}.deliveryComments`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={
                  <>
                    {translate("delivery_comments")}{" "}
                    <small>{translate("optional")}</small>
                  </>
                }
                placeholder="Introduce"
              />
            )}
          />
        </div>
      </main>
    </StyledSend>
  );
};

export default Send;
