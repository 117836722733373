import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Loading, Input } from "@nextui-org/react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { useFetch } from "../../../hooks/useFetch";
import { URL } from "../../../constants";
import { getAllProfiles, editProfile } from "../../../services/auth.services";
import Table from "../../../components/Table/Table";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal/ConfirmationModal";
import DataModal from "../../../components/Modal/DataModal/DataModal";
import RegisterModal from "../../Quotes/NewQuotation/Additional/RegisterModal";
import Button from "../../../components/Button/Button";

const TableServices = () => {
  const token = useSelector((state) => state.user.token);
  const { t: translate } = useTranslation("Admin");

  const [modal, setModal] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [editingCell, setEditingCell] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    }),
    [token]
  );

  const { data: roles } = useFetch(`${URL.get()}roles/`, headers);

  const columns = useMemo(
    () => [
      { name: translate("actions"), uid: "actions", type: "action" },
      {
        name: translate("name"),
        uid: "first_name",
        type: "text",
        editable: true,
      },
      {
        name: translate("lastname"),
        uid: "last_name",
        type: "text",
        editable: true,
      },
      {
        name: translate("document_type"),
        uid: "document_type",
        type: "textCenter",
      },
      { name: translate("document"), uid: "document", type: "text" },
      { name: translate("email"), uid: "email", type: "text", editable: true },
      { name: translate("phone"), uid: "phone", type: "text", editable: true },
      {
        name: translate("industry"),
        uid: "industry",
        type: "text",
        editable: true,
      },
      { name: translate("company"), uid: "company", type: "text" },
      { name: translate("last_login"), uid: "last_login", type: "text" },
      { name: translate("role"), uid: "role", type: "textCenter" },
    ],
    [translate]
  );

  const modalStyle = useMemo(
    () => ({
      display: "none",
      backgroundColor: "rgba(0, 0, 0, .7)",
      position: "absolute",
      top: "0",
      left: "0",
      height: "100%",
      width: "100%",
      zIndex: "300",
    }),
    []
  );

  const [currentModalStyle, setCurrentModalStyle] = useState(modalStyle);

  const closeModal = useCallback(() => {
    setModal(null);
    setCurrentModalStyle({ ...modalStyle, display: "none" });
  }, [modalStyle]);

  const rolModal = useCallback(
    (userData) => {
      const userRoles = roles.filter((rol) => rol.id !== userData.role_id);
      setCurrentModalStyle({ ...modalStyle, display: "block" });
      setModal(
        <DataModal
          data={userRoles}
          titleText={translate("select_rol")}
          buttonText={translate("change_rol")}
          close={closeModal}
          id={userData.id}
          endpoint={`user/${userData.id}`}
          methodType="PATCH"
          headers={headers}
        />
      );
    },
    [roles, translate, closeModal, headers, modalStyle]
  );

  const deleteUserModal = useCallback(
    (userData) => {
      setCurrentModalStyle({ ...modalStyle, display: "block" });
      setModal(
        <ConfirmationModal
          titleText="Eliminar usuario"
          buttonText={translate("delete_user")}
          contentText="¿Esta seguro que desea eliminar este usuario?"
          close={closeModal}
          id={userData.id}
          endpoint={`user/${userData.id}`}
          methodType="DELETE"
          headers={headers}
        />
      );
    },
    [translate, closeModal, headers, modalStyle]
  );

  const actions = useMemo(
    () => [
      {
        name: translate("edit"),
        icon: "Edit",
        type: "button",
        handle: rolModal,
      },
      {
        name: translate("delete"),
        icon: "Trash",
        type: "button",
        handle: deleteUserModal,
      },
    ],
    [translate, rolModal, deleteUserModal]
  );

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const [usersRes] = await Promise.all([getAllProfiles(token)]);
      const [usersData] = await Promise.all([usersRes.json()]);

      const dataTable = usersData.map((user) => ({
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        document_type: user.document_type,
        document: user.document,
        email: user.email,
        phone: user.phone,
        industry: user.industry,
        company: user.organization
          ? user.organization.name
          : translate("no_organization"),
        last_login: user.last_login
          ? moment(user.last_login).format("YYYY-MM-DD HH:mm:ss")
          : "Sin inicio",
        role: user.role ? user.role.name : translate("no_role"),
      }));

      setItems(dataTable);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  }, [token, translate]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDoubleClick = (item, column) => {
    if (column.editable) {
      setEditingCell({
        id: item.id,
        field: column.uid,
        value: item[column.uid],
      });
    }
  };

  const handleEditChange = (e) => {
    setEditingCell({ ...editingCell, value: e.target.value });
  };

  const handleEditBlur = async () => {
    if (editingCell) {
      try {
        const response = await editProfile(token, {
          [editingCell.field]: editingCell.value,
        });
        if (response.ok) {
          setItems(
            items.map((item) =>
              item.id === editingCell.id
                ? { ...item, [editingCell.field]: editingCell.value }
                : item
            )
          );
        } else {
          console.error("Failed to update profile");
        }
      } catch (error) {
        console.error("Failed to update profile:", error);
      }
      setEditingCell(null);
    }
  };

  const renderCell = (item, columnKey) => {
    const column = columns.find((col) => col.uid === columnKey);

    if (
      editingCell &&
      editingCell.id === item.id &&
      editingCell.field === columnKey
    ) {
      return (
        <Input
          aria-label={`Edit ${column.name}`}
          value={editingCell.value}
          onChange={handleEditChange}
          onBlur={handleEditBlur}
          autoFocus
        />
      );
    }

    return (
      <div onDoubleClick={() => handleDoubleClick(item, column)}>
        {item[columnKey]}
      </div>
    );
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);  // Reset to first page when changing rows per page
  };

  return (
    <div className="h-100">
      <Button
        text={"Registrar usuario"}
        bgColor={"primary_300"}
        textColor={"white"}
        handler={() => setOpen(true)}
      />
      <RegisterModal open={open} setOpen={setOpen} getData={getData} />
      {loading ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : items.length > 0 ? (
        <Table
          columns={columns}
          items={items}
          actions={actions}
          renderCell={renderCell}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          total={items.length}
        />
      ) : (
        <div className="d-flex justify-content-center">
          No hay datos disponibles
        </div>
      )}
      <div style={currentModalStyle}>{modal}</div>
    </div>
  );
};

export default React.memo(TableServices);