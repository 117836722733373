import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { getUserNotificationMethods, activateUserNotificationMethod, deactivateUserNotificationMethod } from '../../services/notifications.services';
import { registerDevice } from '../../firebaseFunctions';
import toast from '../../components/Toast/ToastTypes';

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.background.paper,
  borderRadius: '8px',
  margin: '50px',
  overflow: 'hidden',
}));

const Row = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '15px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const IconWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const AnimatedSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-thumb': {
    transition: 'transform 0.3s cubic-bezier(.47,1.64,.41,.8)',
  },
  '&.Mui-checked': {
    '& .MuiSwitch-thumb': {
      transform: 'translateX(20px) rotate(360deg)',
    },
  },
}));

export default function Settings() {
  const [methods, setMethods] = useState([]);
  const token = useSelector(state => state.user.token);

  useEffect(() => {
    getUserNotificationMethods(token).then(async res => {
      const data = await res.json();
      setMethods(data.active_notification_methods);
    });
  }, [token]);

  async function handleChangeNotification(checked, id) {
    try {
      if (checked) {
        const res = await activateUserNotificationMethod(token, id);
        if (id === 2) { // Push notifications
          const permissions = await registerDevice(token);
          console.log('permissions:', permissions);
        }
        if (res.status !== 200) {
          toast("warn", "Error: Es posible que las notificaciones estén bloqueadas para este sitio");
        } else {
          setMethods(prevMethods => [...prevMethods, id]);
        }
      } else {
        await deactivateUserNotificationMethod(token, id);
        setMethods(prevMethods => prevMethods.filter(methodId => methodId !== id));
      }
    } catch (err) {
      console.error('Error:', err);
      toast('error', 'Error al cambiar el método de notificación');
    }
  }

  const notificationMethods = [
    { id: 1, name: 'Email', icon: EmailIcon },
    { id: 2, name: 'Push', icon: NotificationsIcon },
    // { id: 3, name: 'WhatsApp', icon: WhatsAppIcon },
  ];

  return (
    <StyledDiv>
      {notificationMethods.map((method, index) => (
        <React.Fragment key={method.id}>
          <Row>
            <IconWrapper>
              <method.icon style={{ fontSize: 24 }} />
              <p>Activar notificaciones por {method.name}</p>
            </IconWrapper>
            <AnimatedSwitch
              checked={methods.includes(method.name)}
              onChange={(e) => handleChangeNotification(e.target.checked, method.id)}
              color="primary"
            />
          </Row>
          {index < notificationMethods.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </StyledDiv>
  );
}