import React, { useState, useEffect } from 'react';
import { Modal, Button, Loading } from "@nextui-org/react";

const PDFPreviewModal = ({ isOpen, onClose, url }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      const width = Math.min(window.innerWidth * 0.8, 1000); // 80% of window width, max 1000px
      const height = window.innerHeight * 0.8; // 80% of window height
      setDimensions({ width, height });
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
    setError("Failed to load PDF. The file might not be accessible or there could be a network issue.");
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      width={dimensions.width}
      css={{
        height: `${dimensions.height}px`,
        maxWidth: '1000px',
        margin: 'auto',
      }}
    >
      <Modal.Header>
        <h3>PDF Preview</h3>
      </Modal.Header>
      <Modal.Body
        css={{
          height: `${dimensions.height - 130}px`, // Adjusting for header and footer
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {loading && <Loading size="xl">Loading PDF...</Loading>}
        {error && <p>{error}</p>}
        <iframe
          src={`https://docs.google.com/viewer?url=${encodeURIComponent(url)}&embedded=true`}
          width="100%"
          height="100%"
          style={{
            border: 'none',
            display: loading ? 'none' : 'block',
            backgroundColor: '#f0f0f0',
          }}
          onLoad={handleLoad}
          onError={handleError}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button auto flat color="error" onClick={onClose}>
          Close
        </Button>
        <Button auto color="primary" onClick={() => window.open(url, '_blank')}>
          Open in New Tab
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PDFPreviewModal;